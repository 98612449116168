import React, { useContext, useState } from 'react';
import { MdOutlineSentimentVerySatisfied, MdOutlineSentimentVeryDissatisfied, MdOutlineTouchApp } from 'react-icons/md';
import { PiHandsPrayingFill } from 'react-icons/pi';
import CognitionIcon from '@/components/icons/cognitionIcon';
import { Icon, Menu, MenuButton, MenuItem, MenuList, SkeletonCircle, Text} from '@chakra-ui/react';
import { InsightContext } from '../lib/insightContext';
import useInsightApi from '../lib/useInsightApi';
import InsightPicto from './insightPicto';
import { insightTypeDictionnary } from '../lib/insightTypeDictionnary';

export default function InsightType(props) {

  const {onChange} = props;

  const {
    insight,
    rights,
    refresh,
  } = useContext(InsightContext)
  
  const [typeLoading, setTypeLoading] = useState(false);
  const insightApi = useInsightApi();

  const setType = async (type) => {
    try {
      setTypeLoading(true);
      await insightApi.setType(insight.id, type)
      await refresh();
      setTypeLoading(false);
      if(onChange) {
        onChange();
      }
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <>
      {typeLoading &&
        <SkeletonCircle width={"38px"} height={"38px"} flexShrink={0} />
      }
      {!typeLoading && (
        <Menu >
          {({ isOpen }) => (
            <>
              <MenuButton
                cursor="pointer"
                as={InsightPicto}
                type={insight.type}
                pointerEvents={rights.can_update ? 'inherit' : 'none'}
              />
              <MenuList>
                <MenuItem onClick={() => {
                  setType(insightTypeDictionnary.improvement)
                }}>
                  <Icon
                    as={PiHandsPrayingFill}
                    color={'fill.yellow'}
                    boxSize={'15px'}
                    mr={2}
                  />
                  <Text color='fill.yellow' >Improvement Feedback</Text>
                </MenuItem>
                <MenuItem onClick={() => {
                  setType(insightTypeDictionnary.positive)
                }}>
                  <Icon
                    as={MdOutlineSentimentVerySatisfied}
                    color={'fill.green'}
                    boxSize={'15px'}
                    mr={2}
                  />
                  <Text color='fill.green' >Positive Feedback</Text>
                </MenuItem>
                <MenuItem onClick={() => {
                  setType(insightTypeDictionnary.negative)
                }}>
                  <Icon
                    as={MdOutlineSentimentVeryDissatisfied}
                    color={'fill.red'}
                    boxSize={'15px'}
                    mr={2}
                  />
                  <Text color='fill.red' >Negative Feedback</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setType(insightTypeDictionnary.behavior)
                  }}
                >
                  <Icon
                    as={MdOutlineTouchApp}
                    color={'fill.grey'}
                    boxSize={'15px'}
                    mr={2}
                  />
                  <Text color='fill.grey' >Behavior</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setType(insightTypeDictionnary.motivation)
                  }}
                >
                  <Icon
                    as={CognitionIcon}
                    color={'fill.purple'}
                    boxSize={'15px'}
                    mr={2}
                  />
                  <Text color='fill.purple' >Motivation</Text>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      )}
    </>
  );
}
