'use client'

import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Box} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, FormLabel, Input} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import useExperienceApi from '../../../experiences/lib/useExperienceApi';
import useProductsApi from '@/products/lib/useProductsApi';
import { Select } from 'chakra-react-select';
import usePersonaApi from '../../lib/usePersonaApi';
import { PersonaPageContext } from '../lib/personaPageContext';

const NewExperienceInPersonaForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    personaId,
    groupId,
    objectiveDefault
  } = props;

  const {
    productList
  } = useContext(PersonaPageContext);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm({
     mode: "onChange"
  });

  const experienceApi = useExperienceApi();
  const personaApi = usePersonaApi();
  const productApi = useProductsApi();
  const [productOptionsList, setProductOptionsList] = useState();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const objective = data.objective;
      let newExperience;
      if(objective) {
        newExperience = await experienceApi.insertExperience(objective);
        if(groupId) {
          await personaApi.linkManyExperiencesToPersona(personaId, [newExperience.id], groupId);
        } else {
          await personaApi.linkManyExperiencesToPersona(personaId, [newExperience.id]);
        }
        if (data.products) {
          const productIds = data.products.map(p => p.value);
          await experienceApi.linkProductsToExperience(newExperience.id, productIds);
        }
      }
      if(onSubmitted) {
        onSubmitted(newExperience);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    try {
      let personaProducts = Array.from(productList);
      personaProducts = personaProducts.map((p) => {
        return {label: p.name, value: p.id}
      });
      const allProducts = await productApi.fetchMyProducts();
      const otherProducts = [];
      allProducts.forEach((p) => {
        const alreadyExists = !(personaProducts
        .every(personaProduct =>  personaProduct.value !== p.id));
        if(!alreadyExists) {
          otherProducts.push({label: p.name, value: p.id})
        }
      });
      const groupedOptions = [{
        label: 'Used by Persona',
        options: personaProducts
      }, {
        label: (personaProducts && personaProducts.length > 0) ? 'Other Products' : '',
        options: otherProducts
      }]
      setProductOptionsList(groupedOptions);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if(personaId) {
      fetchData();
    }
  }, [personaId])

  useEffect(() => {
    if(objectiveDefault) {
      setValue('objective', objectiveDefault, { shouldTouch: true, shouldValidate: true })
    }
  }, [objectiveDefault])

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Box
            alignItems={'center'}
          >
            <FormControl isInvalid={errors && errors.objective} mb={3}>
              <FormLabel>Objective</FormLabel>
              <Input
                placeholder='Action: Make… Find… Search…'
                {...register('objective', {
                  required: true,
                  minLength: 3
                })}
              />
              <FormErrorMessage>{errors && errors.objective}</FormErrorMessage>
            </FormControl>
            <Controller
              control={control}
              name="products"
              rules={{}}
              render={({
                         field: { onChange, onBlur, value, objective, ref },
                         fieldState: { invalid, error }
                       }) => (
                <FormControl isInvalid={errors && errors.products} mb={3}>
                  <FormLabel>Involved Products </FormLabel>
                  <Select
                    isMulti
                    options={productOptionsList}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                  <FormErrorMessage>{errors && errors.products}</FormErrorMessage>
                </FormControl>
              )}
            />
            <IconButton
              style={{
                display: hideSave ? 'none' : 'inherit',
              }}
              ref={ref}
              icon={<MdCheck />}
              ml={2}
              type={'submit'}
              aria-label='check'
              variant='primary'
              isLoading={isSubmitting}
              isDisabled={!isValid}
            />
          </Box>
        </form>
      </Box>
    </>
  );
});

export default NewExperienceInPersonaForm;


