export const button = {
  baseStyle: {
    borderRadius: '10px',
    fontSize: '12px',
  },
  sizes: {
    md: {
      h: '34',
      px: '6',
      minW: '34',
      fontSize: '12px',
      borderRadius: '10px',
    },
    sm : {
      h: '24px',
      px: '6',
      minW: '24px',
      fontSize: '10px',
      borderRadius: '6px',
    }
  },
  variants: {
    greyFill: {
      bg: 'fill.grey',
      color: 'white',
    }, 
    greyOutline: {
      bg: 'background.grey',
      borderWidth: '1px',
      borderStyle: '1px',
      color: 'fill.grey',
      borderColor: 'divider.grey',
    },
    greyTransparent: {
      color: 'fill.grey',
      py: '0',
      _hover: {
        bg: 'text.disable.grey',
      }
    },
    orangeFill: {
      bg: 'fill.orange',
      color: 'white',
    }, 
    orangeOutline: {
      bg: 'background.orange',
      borderWidth: '1px',
      borderStyle: '1px',
      borderColor: 'divider.orange',
      color: 'fill.orange',
    },
    orangeTransparent: {
      color: 'fill.orange',
      fontWeight: '400',
      py: '0',
    },
    redFill: {
      bg: 'fill.red',
      color: 'white',
    }, 
    greenFill: {
      bg: 'fill.green',
      color: 'white',
    }, 
    redOutline: {
      bg: 'background.red',
      borderWidth: '1px',
      borderStyle: '1px',
      borderColor: 'divider.red',
      color: 'fill.red',
    },
    redTransparent: {
      color: 'fill.red',
      py: '0',
      fontWeight: '400',
    },
    blueFill: {
      bg: 'fill.blue',
      color: 'white',
    }, 
    blueOutline: {
      bg: 'background.blue',
      borderWidth: '1px',
      borderStyle: '1px',
      borderColor: 'divider.blue',
      color: 'fill.blue',
    },
    blueTransparent: {
      color: 'fill.blue',
      padding: '0',
      fontWeight: '400',
    },
    purpleFill: {
      bg: 'fill.purple',
      color: 'white',
    }, 
    purpleOutline: {
      bg: 'background.purple',
      borderWidth: '1px',
      borderStyle: '1px',
      borderColor: 'divider.purple',
      color: 'fill.purple',
    },
    purpleTransparent: {
      color: 'fill.purple',
      padding: '0',
      fontWeight: '400',
    },
    inline: {
      bg: 'transparent',
      fontWeight: 'regular',
      color: 'text.primaryBlue',
      padding: '0px !important',
      textDecoration: 'underline'
    },
  },
  defaultProps: {
    variant: 'greyOutline',
    size: 'md',
  }
};