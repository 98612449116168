import React, { useEffect, useState, useContext, useRef } from 'react';
import { Box, Flex, Divider, Spacer, Text } from '@chakra-ui/layout';
import { Button, IconButton, useToast, Tag, TagLabel, Skeleton } from '@chakra-ui/react'
import { MdArrowBack, MdLink, MdPersonAdd } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import useProfileApi from '@/profile/lib/useProfileApi';
import ProfileThumbnail from '@/profile/component/profileThumbnail';
import { useForm } from 'react-hook-form';
import PersonaUserTempListItem from './personaUserTempListItem';
import roleDictionnary from '@/lib/roleDictionnary';
import { Select } from 'chakra-react-select';
import memberStatusDictionnary from '@/lib/memberStatusDictionnary';
import usePersonaApi from '../../lib/usePersonaApi';
import { PersonaContext } from '../../lib/personaContext';
import PersonaMemberListItem from './personaMemberListItem';
import { AuthContext } from '@/auth/lib/authContext';
import ProfileProvider from '@/profile/lib/profileProvider';
import PersonaSelectedMembers from './personaSelectedMembers';
import AddProfileInput from '../../../profile/component/AddProfileInput';

export default function PersonaMembers(props) {

  const {
    personaId,
    role,
    refresh,
    hasRequestedEdition
  } = useContext(PersonaContext);

  const { profile } = useContext(AuthContext);
  const profileApi = useProfileApi();
  const personaApi = usePersonaApi();
  const [profiles, setProfiles] = useState(false);
  const [owner, setOwner] = useState();
  const [ownerEmail, setOwnerEmail] = useState();
  const [user_temp, setUsersTemps] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [addingMembers, setAddingMembers] = useState(false)
  const [isSendingRequest, setIsSendingRequest] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [status, setStatus] = useState()
  const [membersRole, setMembersRole] = useState(roleDictionnary.contributor)
  const membersModal = useDisclosure();
  const addMembersModal = useDisclosure();
  const requestEditModal = useDisclosure();
  const toast = useToast();
  const formMember = useForm({ mode: 'onChange' });
  const formAddMember = useForm({ mode: 'onChange' });
  const modaleRef = useRef()
  const modaleAddRef = useRef()

  const fetchData = async () => {
    try {
      setIsFetching(true);

      const promises = [
        profileApi.fetchMembersProfilesByPersonaId(personaId ),
        profileApi.fetchPersonaOwnerByPersonaId(personaId),
        profileApi.fetchTemporaryUsersByPersonaId(personaId),
        personaApi.fetchMemberStatus(personaId, profile.id),
      ];
      const result = await Promise.all(promises);
      setProfiles(result[0]);
      setOwner(result[1]);
      setUsersTemps(result[2]);
      setStatus(result[3]);
      const newOwnerEmail = await profileApi.fetchEmailByProfileId(result[1].id);
      setOwnerEmail(newOwnerEmail);
      setIsFetching(false);
    } catch (e) {
      console.error(e)
    }
  };

  const onAddFirstUser = async (p) => {
    try {
      const newSelectedMembers = Array.from(selectedMembers)
      newSelectedMembers.push(p)
      setSelectedMembers(newSelectedMembers);
      addMembersModal.onOpen();
      membersModal.onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const resetModal = () => {
    addMembersModal.onClose();
    membersModal.onOpen();
    formMember.setValue('people', '')
    setSelectedMembers([])
  }

  const addNewUser = async (p) => {
    try {
      const newSelectedMembers = Array.from(selectedMembers)
      if (newSelectedMembers.indexOf(p) === -1) {
        newSelectedMembers.push(p);
      }
      formAddMember.reset();
      setSelectedMembers(newSelectedMembers);
      addMembersModal.onOpen();
      membersModal.onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const addMembers = async () => {
    try {
      setAddingMembers(true);
      await personaApi.addMembers(selectedMembers, personaId, membersRole);
      await fetchData();
      resetModal();
      setAddingMembers(false);
    } catch (error) {
      console.error(error);
    }
  }

  const sendEditRequest = async () => {
    try {
      setIsSendingRequest(true);
      await personaApi.requestEdit(personaId, profile.id);
      await refresh();
      await fetchData();
      requestEditModal.onClose();
      setIsSendingRequest(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (personaId) {
      fetchData();
    }
  }, [personaId, role]);

  return (
    <>
      {isFetching && (
        <Skeleton w={"150px"} height={"30px"} />
      )}
      {!isFetching && (
        <Flex alignItems={'center'}>
          {profiles && (
            <Flex
              position={'relative'}
              cursor={'pointer'}
              onClick={membersModal.onOpen}
              w={(((profiles.length * 20) + 10) + 'px')}
            >
              {profiles.map((p, i) => {
                return (
                  <Box
                    key={i}
                    position={'relative'}
                    left={i > 0 ? (-10 * i + 'px') : null} >
                    <ProfileProvider
                      profileId={p.id}
                      originalProfile={p}>
                      <ProfileThumbnail size={30} />
                    </ProfileProvider>
                  </Box>
                )
              })}
            </Flex>
          )}
          {(role && role !== roleDictionnary.viewer
            && status !== memberStatusDictionnary.requested) && (
              <Button
                variant="blueFill"
                leftIcon={<MdPersonAdd />}
                ml={2}
                onClick={membersModal.onOpen}
              >Share</Button>
            )}
          {(role && role !== roleDictionnary.viewer
            && status === memberStatusDictionnary.requested) && (
              <Tag variant="yellow">
                <TagLabel>Edition Requested</TagLabel>
              </Tag>
            )}
          {((!role || (role && role === roleDictionnary.viewer)) && !hasRequestedEdition) && (
            <Button
              variant="greyOutline"
              ml={2}
              onClick={requestEditModal.onOpen}
            >Request to Edit</Button>
          )}
          {((!role || (role && role === roleDictionnary.viewer)) && hasRequestedEdition) && (
            <Tag variant="yellow" ml={2}>
              <TagLabel>Edition Request Pending</TagLabel>
            </Tag>
          )}
          <Modal
            isOpen={membersModal.isOpen}
            onClose={membersModal.onClose}
            trapFocus={false}
          >
            <ModalOverlay />
            <ModalContent ref={modaleRef} >
              <ModalHeader>Members</ModalHeader>
              <ModalBody p={3}  >
                {(role && role !== roleDictionnary.viewer) && (
                  <Box mb={2} >
                    <AddProfileInput 
                      placeholder='Add People by searching or typing email'
                      clickTarget={modaleRef}
                      variant="blueOutline"
                      onChange={(e) => {
                        onAddFirstUser(e.email)
                      }}
                    />
                  </Box>
                )}
                <Box
                  borderWidth="1px"
                  borderColor="divider.grey"
                  borderRadius="2px"
                  borderStyle={'solid'}
                >
                  {profiles && profiles.map((p, i) => {
                    return (
                      <Box key={i}>
                        {i > 0 && <Divider />}
                        <ProfileProvider
                          profileId={p.id}
                          originalProfile={p}
                        >
                          <PersonaMemberListItem
                            onRevoke={() => {
                              setProfiles(null)
                              fetchData()
                            }}
                            onRoleUpdated={() => {
                              setProfiles(null)
                              fetchData();
                            }}
                          />
                        </ProfileProvider>
                      </Box>
                    )
                  })}
                  {role && (
                    <>
                      {(user_temp && user_temp.length > 0) && <Divider />}
                      {user_temp && user_temp.map((u, i) => {
                        return (
                          <Box key={i}>
                            {i > 0 && <Divider />}
                            <PersonaUserTempListItem userTemp={u} />
                          </Box>
                        )
                      })}
                    </>
                  )}
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="greyOutline"
                  onClick={() => {
                    const url = window.location.href;
                    navigator.clipboard.writeText(url).then(() => {
                      toast({
                        position: 'bottom-right',
                        description: "Link Copied",
                        duration: 1000,
                        isClosable: true
                      });
                    })
                  }}
                  leftIcon={<MdLink />}
                >Copy Link</Button>
                <Spacer />
                <Button
                  variant="blueOutline"
                  ml={2}
                  onClick={membersModal.onClose}
                >Done</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={addMembersModal.isOpen}
            onClose={addMembersModal.onClose}
          >
            <ModalOverlay />
            <ModalContent >
              <ModalHeader>
                <IconButton
                  fontSize='24'
                  variant="tertiary"
                  icon={<MdArrowBack />}
                  mr={2}
                  onClick={resetModal}
                />
                Add Members
              </ModalHeader>
              <ModalBody p={3} ref={modaleAddRef} >
                <Box mb={2} >
                    <PersonaSelectedMembers
                      selectedMembers={selectedMembers}
                      onRemoveSelectedMember={(i) => {
                        let newSelectedMembers = Array.from(selectedMembers);
                        newSelectedMembers.splice(i, 1);
                        if (newSelectedMembers.length < 1) {
                          resetModal();
                        }
                        setSelectedMembers(newSelectedMembers)
                      }}
                    />
                    <Box mb={2}>
                      <Select
                        options={[
                          { value: roleDictionnary.viewer, label: roleDictionnary.viewer },
                          { value: roleDictionnary.contributor, label: roleDictionnary.contributor },
                        ]}
                        defaultValue={{ value: roleDictionnary.contributor, label: roleDictionnary.contributor }}
                        onChange={(option) => { setMembersRole(option.value) }}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </Box>
                    <Flex alignItems={'center'} >
                    <AddProfileInput 
                      placeholder='Add People by searching or typing email'
                      clickTarget={modaleAddRef}
                      variant="blueOutline"
                      onChange={(e) => {
                        addNewUser(e.email)
                      }}
                    />
                    </Flex>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="blueOutline"
                  ml={2}
                  onClick={resetModal}
                  disabled={addingMembers}
                >Back</Button>
                <Button
                  variant="blueFill"
                  ml={2}
                  isLoading={addingMembers}
                  onClick={addMembers}
                >Send</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={requestEditModal.isOpen}
            onClose={requestEditModal.onClose}
          >
            <ModalOverlay />
            <ModalContent >
              <ModalHeader>Request Edit</ModalHeader>
              <ModalBody p={3}  >
                {(owner && ownerEmail) && (
                  <Box
                    px={3}
                    py={3}
                    border="1px"
                    borderColor="divider.grey"
                    backgroundColor="white"
                    borderRadius="2px"
                  >
                    <Text mb={2} >A request will be sent to {owner.pseudo}</Text>
                    <Flex>
                      <Box
                        mr={2}
                      >
                        <ProfileProvider
                          profileId={owner.id}
                          originalProfile={owner}
                        >
                          <ProfileThumbnail size={40} />
                        </ProfileProvider>
                      </Box>
                      <Box>
                        <Text>{owner.pseudo}</Text>
                        <Text textStyle="subtitle" >{ownerEmail}</Text>
                      </Box>
                    </Flex>
                  </Box>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="blueOutline"
                  ml={2}
                  onClick={requestEditModal.onClose}
                >Cancel</Button>
                <Button
                  variant="blueFill"
                  ml={2}
                  isLoading={isSendingRequest}
                  disabled={isSendingRequest}
                  onClick={sendEditRequest}
                >Send</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      )}
    </>

  )
}
