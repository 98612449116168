import React, { useContext, useEffect, useRef, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { MdDevices, MdFace } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import {
  Button,
  Icon,
  Tooltip,
} from '@chakra-ui/react'
import { InsightContext } from '../lib/insightContext';
import PersonaTag from '@/persona/[id]/components/personaTag';
import ProductTag from '@/products/components/productTag';
import InsightPersonaListForm from './insightPersonaListForm';
import InsightProductListForm from './insightProductListForm';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import ProductProvider from '@/products/lib/productProvider';
import PersonaProvider from '@/persona/lib/personaProvider';
import ProductLogo from '@/products/components/productLogo';
import PersonaThumbnail from '@/persona/components/personaThumbnail';

export default function InsightListItemPersonaProducts(props) {

  const {
    insight,
    personaList,
    productList,
    rights
  } = useContext(InsightContext)

  const [isAdding, setIsAdding] = useState(false);

  const modalPersona = useDisclosure({ defaultIsOpen: true });
  const ref1 = useRef(null);
  const [isValid, setIsValid] = useState(false);

  const modalProduct = useDisclosure({ defaultIsOpen: true });
  const ref2 = useRef(null);

  return (
    <>
      {insight && (
        <Flex
          mt={3}
        >
          <InlineFormWrapper
            canEdit={rights.can_update}
            value={
              <Flex
                alignItems="center"
              >
                <Icon
                  as={MdFace}
                  size="20"
                  color="text.medium.grey"
                />
                {insight.applicable_to_all_persona && (
                  <Text
                    ml="1"
                    color="text.medium.grey"
                  >
                    All
                  </Text>
                )}
                {!insight.applicable_to_all_persona && (
                  <Flex>
                    {personaList && personaList.map((p, i) => (
                      <Tooltip
                        label={p.name}
                        key={i}
                      >
                        <Flex
                          ml={2}
                        >
                          <PersonaProvider
                            personaId={p.id}
                            originalPersona={p}
                            needRights={false}
                            needRole={false}
                            needEditionRequest={false}
                          >
                            <PersonaThumbnail
                              size={20}
                              hasAcronym={true}
                            />
                          </PersonaProvider>
                        </Flex>
                      </Tooltip>
                    ))}
                  </Flex>
                )}
              </Flex>
            }
          >
            {(close) => (
              <Modal isOpen={modalPersona.isOpen} onClose={() => {
                close();
              }
              }>
                <ModalOverlay />
                <ModalContent >
                  <ModalHeader>Update Persona List</ModalHeader>
                  <ModalBody p={3}>
                    <InsightPersonaListForm
                      hideSave={true}
                      onSubmitting={() => {
                        setIsAdding(true);
                      }}
                      onSubmitted={() => {
                        setIsAdding(false);
                        close();
                      }}
                      onValidityChange={setIsValid}
                      ref={ref1}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant='orangeOutline'
                      onClick={close}
                    >Cancel</Button>
                    <Button
                      variant="orangeFill"
                      ml={2}
                      isLoading={isAdding}
                      isDisabled={!isValid}
                      onClick={() => {
                        ref1.current.click();
                      }}>Save</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </InlineFormWrapper>
          <Flex px={2} />
          <InlineFormWrapper
            canEdit={rights.can_update}
            value={
              <Flex
                alignItems="center"
              >
                <Icon
                  as={MdDevices}
                  size="20"
                  color="text.medium.grey"
                />
                {insight.applicable_to_all_products && (
                  <Text
                    ml="1"
                    color="text.medium.grey"
                  >
                    All
                  </Text>
                )}
                {!insight.applicable_to_all_products && (
                  <Flex>
                    {productList && productList.map((p, i) => (
                      <Tooltip
                        label={p.name}
                        key={i}
                      >
                        <Flex
                          ml={2}
                        >
                          <ProductProvider
                            productId={p.id}
                            orignalProduct={p}
                            needRights={false}
                            needRole={false}
                            needEditionRequest={false}
                          >
                            <ProductLogo
                              size={20}
                              hasAcronym={true}
                            />
                          </ProductProvider>
                        </Flex>
                      </Tooltip>
                    ))}
                  </Flex>
                )}
              </Flex>
            }
          >
            {(close) => (
              <Modal isOpen={modalProduct.isOpen} onClose={close}>
                <ModalOverlay />
                <ModalContent >
                  <ModalHeader>Update Products</ModalHeader>
                  <ModalBody p={3}>
                    <InsightProductListForm
                      hideSave={true}
                      onSubmitting={() => {
                        setIsAdding(true);
                      }}
                      onSubmitted={() => {
                        setIsAdding(false);
                        close();
                      }}
                      onValidityChange={setIsValid}
                      ref={ref2}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant='orangeOutline'
                      onClick={close}
                    >Cancel</Button>
                    <Button
                      variant="orangeFill"
                      ml={2}
                      isLoading={isAdding}
                      isDisabled={!isValid}
                      onClick={() => {
                        ref2.current.click();
                      }}>Save</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </InlineFormWrapper>
        </Flex>
      )}
    </>
  );
}
