import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Flex,
  Progress,
  Text,
  Box,
  IconButton
} from "@chakra-ui/react";
import { MdAdd, MdOutlinePeople } from "react-icons/md";
import useProfileApi from "../lib/useProfileApi";
import ProfileProvider from '@/profile/lib/profileProvider';
import { ProfileContext } from "../lib/profileContext";
import ProfileThumbnail from '@/profile/component/profileThumbnail';


const AddProfileInput = (props) => {
  const [profiles, setProfiles] = useState();
  const [profileFiltered, setProfileFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [output, setOutput] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const profileApi = useProfileApi();
  const [menuHover, setMenuHover] = useState(false);
  const menuHoverRef = useRef(false);
  const [inputHover, setInputHover] = useState(false);
  const [isValidEmailAdress, setIsValidEmailAddress] = useState(false);
  const inputHoverRef = useRef(false);
  const [top, setTop] = useState();
  const [left, setLeft] = useState();
  const [width, setWidth] = useState();
  const {
    placeholder = 'Search people',
    clickTarget,
    onChange,
    variant
  } = props;


  const fetchProfiles = async () => {
    setIsLoading(true);
    const newProfiles = await profileApi.fetchProfileRecommendations('');
    setProfiles(newProfiles)
    setIsLoading(false);
  }

  const handleSearch = async (event) => {
    let newSearchTearm = '';
    if (event?.target?.value) {
      newSearchTearm = event.target.value;
    }
    setSearchTerm(newSearchTearm);
    setIsValidEmailAddress((/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(newSearchTearm))
    if (!profiles) {
      setIsLoading(true);
      const newProfiles = await profileApi.fetchProfileRecommendations(newSearchTearm);
      setProfiles(newProfiles)
      setIsLoading(false);
    } else {
      const newProfileFiltered = profiles.filter((p) => {
        return (
          p.pseudo.toLowerCase().includes(newSearchTearm.toLowerCase())
          || p.email.toLowerCase().includes(newSearchTearm.toLowerCase())
        )
      });
      setProfileFiltered(newProfileFiltered);
    }
  };

  const updateMenuPosition = () => {
    const input = inputRef.current;
    let rect = input.getBoundingClientRect();
    setTop((rect.top + rect.height + 5) + 'px')
    setLeft(rect.left + 'px')
    setWidth(rect.width + 'px')
  }

  const handleFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClick = useCallback((e) => {
    const isFocus = document.activeElement === inputRef.current;
    if (!menuHoverRef.current && !inputHover.current && !isFocus) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    let clickEvent;
    if (clickTarget && clickTarget.current) {
      clickEvent = clickTarget.current.addEventListener('click', handleClick);
    } else {
      clickEvent = window.addEventListener('click', handleClick);
    }
    return () => {
      if (clickTarget && clickTarget.current) {
        clickTarget.current.removeEventListener('click', clickEvent);
      } else {
        window.removeEventListener('click', clickEvent);
      }
    }
  }, [])

  useEffect(() => {
    menuHoverRef.current = menuHover;
  }, [menuHover])

  useEffect(() => {
    inputHoverRef.current = inputHover;
  }, [inputHover])

  useEffect(() => {
    if (!profiles) {
      fetchProfiles();
    }
  }, [profiles])


  useEffect(() => {
    setTimeout(() => {
      updateMenuPosition();
    }, 10)
  }, [isOpen])

  useEffect(() => {
    setSearchTerm('');
    if (onChange && output) {
      onChange(output);
    }
  }, [output])

  return (
    <Flex
      width="100%"
      position={"relative"}
      alignItems={"center"} >
      <InputGroup
        onMouseEnter={() => {
          setInputHover(true)
        }}
        onMouseLeave={() => {
          setInputHover(false)
        }}
      >
        <InputLeftElement pointerEvents="none">
          <Icon as={MdOutlinePeople} color="text.medium.grey" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearch}
          onFocus={handleFocus}
          ref={inputRef}
        />
      </InputGroup>
      {(searchTerm && isValidEmailAdress && profileFiltered.length === 0) &&
        <IconButton
          icon={<MdAdd />}
          ml={2}
          variant={variant}
          onClick={() => {
            setOutput({
              known: false,
              email: searchTerm
            })
          }}
        />
      }
      {(isOpen && (isLoading || profileFiltered.length > 0))  && (
        <Flex
          position={"fixed"}
          zIndex="10000000000000"
          left={left}
          top={top}
          width={width}
          backgroundColor={"white"}
          flexDirection={"column"}
          maxHeight={"50vh"}
          borderRadius={"2px"}
          border={"1px solid"}
          borderColor={"divider.grey"}
        >
          {isLoading && (
            <Progress isIndeterminate size='xs' opacity={isLoading ? '1' : '0'} />
          )}
          {profileFiltered.length > 0 && (
            <Flex
              flexDirection={"column"}
              boxShadow={'0px 0px 6px 0px rgba(163,163,163,0.15)'}
              onMouseEnter={() => {
                setMenuHover(true)
              }}
              onMouseLeave={() => {
                setMenuHover(false)
              }}
            >
              {profileFiltered.map((p, i) => (
                <Box
                  key={i}
                  borderBottom={"1px solid"}
                  borderColor={i < (profileFiltered.length - 1) ? "divider.grey" : 'transparent'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsOpen(false);
                    setOutput({
                      known: true,
                      profile: p,
                      email: p.email
                    })
                  }}
                >
                  <ProfileProvider
                    profileId={p.id}
                    originalProfile={p}
                  >
                    <SearchProfileListItem />
                  </ProfileProvider>
                </Box>
              ))}
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

const SearchProfileListItem = () => {

  const { profile } = useContext(ProfileContext)

  return (<>
    {profile && (
      <Flex
        alignItems={"center"}
        _hover={{
          background: 'background.grey'
        }}
        px={3}
        py={2}>
        <ProfileThumbnail size={30} />
        <Text ml={2}>{profile.pseudo}</Text>
      </Flex>
    )}
  </>)
}


export default AddProfileInput;