import React, { useContext, useEffect, useRef, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import InsightNameForm from './insightNameForm';
import { InsightContext } from '../lib/insightContext';
import InsightListItemPersonaProducts from './insightListItemPersonaProducts';
import { useParams } from 'react-router-dom';
import InsightAttributesTags from './insightAttributesTags';
import InsightListItemMenu from './insightListItemMenu';
import InsightFigures from './insightFigures';
import InsightType from './insightType';
import { SlideshowContext } from '../lib/slideshowContext';
import { Button, Skeleton } from '@chakra-ui/react';

export default function InsightListItem(props) {

  const {
    insight,
    rights,
  } = useContext(InsightContext)

  const slideshowContext = useContext(SlideshowContext)

  const {
    onArchived,
    onUnarchived,
    onMovedToStart,
    onMovedToEnd,
    onDelete,
    orderMax
  } = props;
  
  const params = useParams();
  const ref = useRef()

  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [nbOfLines, setNbOfLines] = useState(3);
  const [displaySeeMore, setDisplaySeeMore] = useState(false);

  const checkOverflow = () => {
    const element = textRef.current;
    if (element) {
      setIsOverflowing(element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight);
    }
  }
  useEffect(() => {
   checkOverflow();
  }, []);

  useEffect(() => {
    if (ref && params && insight) {
      const hash = window.location.hash;
      const hasInsightId = parseInt(hash.replace('#', ''))
      if (hasInsightId === insight.id) {
        ref.current.scrollIntoView()
      }
      checkOverflow();
    }
  }, [params, ref.current, insight]);


  // important skeleton height and card should be the same for scroll
  return (
    <>
      {!insight && (
        <Flex minHeight={"230px"}> 
          <Skeleton height={"100%"} width={"100%"} />
        </Flex>
      )}
      {insight && (
        <Flex
          backgroundColor="white"
          w={"100%"}
          position={"relative"}
          minHeight={"230px"}
        >
          <Flex
            p={3}
            flexDirection={"column"}
            justifyContent={"space-between"}
            ref={ref}
            width={"100%"}
          >
            <Flex width={"100%"}>
              <Flex flex={"1"} width={"100%"}>
                <InsightType />
                <Flex
                  flexDirection="column"
                  ml={3}
                  width={"100%"}
                >
                  <Flex flexDirection={"column"} alignItems={'flex-start'}>
                  <InlineFormWrapper
                    width="100%"
                    maxWidth={"400px"}
                    minWidth={"250px"}
                    canEdit={rights.can_update}
                    onToggle={(isOpen) => {
                      setDisplaySeeMore(!isOpen);
                    }}
                    value={
                      <Text
                        ref={textRef}
                        noOfLines={nbOfLines}
                        maxW={400}
                        style={{
                          whiteSpace: 'break-spaces'
                        }}
                      >
                        {insight.name}
                      </Text>
                    }
                  >
                    {(close) => (
                      <InsightNameForm
                        onSubmitted={close}
                        onCancel={close}
                      />
                    )}
                  </InlineFormWrapper>
                    {(displaySeeMore && isOverflowing && nbOfLines === 3) && (
                     <Button height={"25px"} px={0} variant={"orangeTransparent"} onClick={() => { setNbOfLines(100) }}>See more</Button>)
                    }
                    {(displaySeeMore && isOverflowing && nbOfLines === 100) && (
                     <Button height={"25px"} px={0} variant={"orangeTransparent"} onClick={() => { setNbOfLines(3) }}>See less</Button>)
                    }
                  </Flex>
                  <Flex
                    alignItems={"flex-end"}
                    gap={1}
                    cursor={"pointer"}
                  >
                    <Text
                      pt={1}
                      fontSize={"12px"}
                      cursor={(insight.quotes.length || insight.image_count) ? "pointer" : "inherit"}
                      onClick={() => {
                        slideshowContext.playFromInsight(insight.id)
                      }}
                      color="text.medium.grey">{insight.quotes.length} quote{insight.quotes.length > 1 ? 's' : ''}</Text>
                    <Text fontSize={"12px"} color="text.medium.grey">•</Text>
                    <Text
                      pt={1}
                      fontSize={"12px"}
                      cursor={(insight.quotes.length || insight.image_count) ? "pointer" : "inherit"}
                      onClick={() => {
                        slideshowContext.playFromInsight(insight.id)
                      }}
                      color="text.medium.grey">{insight.image_count} Image{insight.image_count > 1 ? 's' : ''}
                    </Text>
                  </Flex>
                  <Flex mt={2}>
                    <InsightAttributesTags />
                  </Flex>
                </Flex>
                <InsightListItemMenu
                  onArchived={onArchived}
                  onUnarchived={onUnarchived}
                  onMovedToStart={onMovedToStart}
                  onMovedToEnd={onMovedToEnd}
                  orderMax={orderMax}
                  onDelete={onDelete}
                  />
              </Flex>
            </Flex>
            <InsightListItemPersonaProducts />
          </Flex>
          <InsightFigures />
        </Flex>
      )}
    </>
  );
}
