import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Flex, Heading, Text, Stack } from '@chakra-ui/layout';
import { Button, ButtonGroup, IconButton, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { insightTypeDictionnary } from '../lib/insightTypeDictionnary';
import InsightListItem from './insightListItem';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { MdAdd, MdKeyboardArrowDown, MdKeyboardDoubleArrowDown, MdOutlineSlideshow, MdOutlineStart, MdOutlineSentimentVeryDissatisfied, MdOutlineSentimentVerySatisfied, MdOutlineTouchApp } from 'react-icons/md';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import InsightForm from './insightForm';
import { MdArrowBack } from 'react-icons/md';
import ExperienceForm from '../../experiences/component/experienceForm';
import useInsightApi from '../lib/useInsightApi';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Icon } from '@chakra-ui/icon';
import { PiHandsPrayingFill } from 'react-icons/pi';
import InsightProvider from '../lib/insightProvider';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import CognitionIcon from '@/components/icons/cognitionIcon';
import { ExperienceContext } from '@/experiences/lib/experienceContext';
import { SlideshowContext } from '../lib/slideshowContext';

export default function InsightList(props) {

  const {
    rights,
    experienceId,
    archivedInsights,
    activeInsights,
    isFetchingInsights,
    refreshInsights
} = useContext(ExperienceContext);

  const {
    personaId,
    productId,
    onChange,
  } = props;

  const slideshowContext = useContext(SlideshowContext);
  const insightApi = useInsightApi();
  const [tabIndex, setTabIndex] = useState(0);
  const [isAdding, setIsAdding] = useState(false);
  const [formValidity, setFormValidity] = useState(false);
  const [archived, setArchived] = useState(false);
  const [actives, setActives] = useState(false);
  const [currentScroll, setCurrentScroll] = useState(-1);
  const [needScroll, setNeedScroll] = useState(false);
  const [type, setType] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalCreateInsight = useDisclosure();
  const ref = useRef(null);
  const ref1 = useRef(null);
  const activepanelRef = useRef(null);
  const scrollRefActive = useRef();
  const panelRef = useRef();
  const panelRefArchived = useRef();

  function compare(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  const updateOrderLocally = (dropArea, insightId, source, destination) => {
    if (dropArea === 'drop-area-active') {
      let newActives;
      if (destination < source) {
        newActives = actives.map((a) => {
          if (
            a.order < source
            && a.order >= destination
            && a.id !== insightId
          ) {
            a.order++
          } else if (a.id === insightId) {
            a.order = destination;
          }
          return a;
        })
      } else if (destination > source) {
        newActives = actives.map(a => {
          if (a.order > source
            && a.order <= destination
            && a.id !== insightId) {
            a.order--;
          } else if (a.id === insightId) {
            a.order = destination;
          }
          return a;
        })
      }
      newActives = newActives.sort(compare);
      setActives(newActives);
    } else if (dropArea === 'drop-area-archived') {
      let newArchived;
      if (destination < source) {
        newArchived = archived.map((a) => {
          if (
            a.order < source
            && a.order >= destination
            && a.id !== insightId
          ) {
            a.order++
          } else if (a.id === insightId) {
            a.order = destination;
          }
          return a;
        })
      } else if (destination > source) {
        newArchived = archived.map(a => {
          if (a.order > source
            && a.order <= destination
            && a.id !== insightId) {
            a.order--;
          } else if (a.id === insightId) {
            a.order = destination;
          }
          return a;
        })
      }
      newArchived = archived.sort(compare);
      setArchived(newArchived);
    }
  };

  const onDragEnd = async (result) => {
    const { draggableId, source, destination } = result;
    try {
      if (destination && destination.index !== source.index) {
        updateOrderLocally(
          destination.droppableId,
          parseInt(draggableId),
          source.index,
          destination.index
        );
        await insightApi.updateOrder(
          destination.index,
          draggableId
        );
      }
    } catch (error) {
      console.error(error)
    }
  }

  const performScroll = () => {
    let panel;
    if(tabIndex === 0) {
      panel = panelRef.current;
    } else {
      panel = panelRefArchived.current;
    }
    if(panel) {
      if(currentScroll > -1) {
        const element = panel.firstChild
        element.scrollTop = currentScroll;
        setTimeout(() => {
            if(needScroll) {
              element.scrollTop = currentScroll;
              setNeedScroll(false);
              setCurrentScroll(-1)
            }
        }, 300) 
      } else {
        const element = panel.firstChild
        element.scrollTop = element.scrollHeight;
        setTimeout(() => {
          element.scrollTop = element.scrollHeight;
          setNeedScroll(false);
        }, 300) 
      }
    }
  }

  useEffect(() => {
    if(tabIndex === 0 && activeInsights) {
        slideshowContext.setInsights(activeInsights);
    }
  }, [tabIndex, activeInsights])

  useEffect(() => {
    if(tabIndex === 1 && archivedInsights) {
      slideshowContext.setInsights(archivedInsights);
    }
  }, [tabIndex, archivedInsights])

  useEffect(() => {
    if(activeInsights) {
      if(tabIndex === 0 && activeInsights) {
        slideshowContext.setInsights(activeInsights);
    }
      setActives(activeInsights);
  }
  }, [activeInsights])

  useEffect(() => {
    if(archivedInsights) {
      if(tabIndex === 1 && archivedInsights) {
        slideshowContext.setInsights(archivedInsights);
      }
      setArchived(archivedInsights);
    }
  }, [archivedInsights])

  const getMenu = () => {
    return (
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              size="md"
              as={IconButton}
              icon={<MdAdd />}
              variant='orangeOutline'
            />
            <MenuList>
              <MenuItem onClick={() => {
                setType(insightTypeDictionnary.improvement)
                onOpen();
              }}>
                <Icon
                  as={PiHandsPrayingFill}
                  color={'fill.yellow'}
                  boxSize={'15px'}
                  mr={2}
                />
                <Text color='fill.yellow' >Improvement Feedback</Text>
              </MenuItem>
              <MenuItem onClick={() => {
                setType(insightTypeDictionnary.positive)
                onOpen()
              }}>
                <Icon
                  as={MdOutlineSentimentVerySatisfied}
                  color={'fill.green'}
                  boxSize={'15px'}
                  mr={2}
                />
                <Text color='fill.green' >Positive Feedback</Text>
              </MenuItem>
              <MenuItem onClick={() => {
                setType(insightTypeDictionnary.negative)
                onOpen();
              }}>
                <Icon
                  as={MdOutlineSentimentVeryDissatisfied}
                  color={'fill.red'}
                  boxSize={'15px'}
                  mr={2}
                />
                <Text color='fill.red' >Negative Feedback</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setType(insightTypeDictionnary.behavior)
                  onOpen();
                }}
              >
                <Icon
                  as={MdOutlineTouchApp}
                  color={'fill.grey'}
                  boxSize={'15px'}
                  mr={2}
                />
                <Text color='fill.grey' >Behavior</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setType(insightTypeDictionnary.motivation)
                  onOpen();
                }}
              >
                <Icon
                  as={CognitionIcon}
                  color={'fill.purple'}
                  boxSize={'15px'}
                  mr={2}
                />
                <Text color='fill.purple' >Motivation</Text>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    )
  }

  return (
    <>
        {(!!actives.length && panelRef.current && panelRef.current.firstChild &&  panelRef.current.firstChild.scrollHeight > window.innerHeight) &&
      <Flex
      position="absolute"
      zIndex={"1000"}
      bottom={"20px"}
      right={"20px"}
      >
        <IconButton
          icon={<Icon as={MdKeyboardDoubleArrowDown}
          variant={"greyTransparent"}
          boxSize={"20px"} />}
          onClick={() => {
            setCurrentScroll(panelRef.current.firstChild.scrollHeight)
            setNeedScroll(true);
            performScroll();
          }}
        />
      </Flex>
      }
      {(!!archived.length && panelRefArchived.current && panelRefArchived.current.firstChild &&  panelRefArchived.current.firstChild.scrollHeight > window.innerHeight) &&
      <Flex
      position="absolute"
      zIndex={"1000"}
      bottom={"20px"}
      right={"20px"}
      >
        <IconButton
          icon={<Icon as={MdKeyboardDoubleArrowDown}
          variant={"greyTransparent"}
          boxSize={"20px"} />}
          onClick={() => {
            setCurrentScroll(panelRefArchived.current.firstChild.scrollHeight)
            setNeedScroll(true);
            performScroll();
          }}
        />
      </Flex>
      }
      <Flex
        backgroundColor="white"
        w="100%"
        borderRadius="2px"
        flexDirection={'column'}
        h="100%"
      >
        <Flex
          alignItems='center'
          py={2}
          px={3}
          borderBottom="1px"
          borderRadius="2px"
          borderColor="divider.grey"
          h={"55px"}
          flexShrink={0}
          justifyContent={"space-between"}
        >
          <Flex gap={3} alignItems={"center"}>
              <Text fontSize={"16px"} >Insights</Text>
              {rights.can_update &&
                getMenu()
              }
          </Flex>
          <Flex>
            {(activeInsights || archivedInsights) && (
              <ButtonGroup isAttached variant='outline'>
                <Button
                  leftIcon={<Icon boxSize={"16px"} as={MdOutlineSlideshow} />}
                  variant={"orangeOutline"}
                  onClick={() => {
                    slideshowContext.play();
                  }}
                >Present</Button>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        isActive={isOpen}
                        as={IconButton}
                        variant={"orangeOutline"}
                        icon={<MdKeyboardArrowDown />}
                      />
                      <MenuList>
                        <MenuItem onClick={() => {
                          slideshowContext.playReverse();
                        }}>
                          <Icon as={MdOutlineStart} transform={"scaleX(-1)"} mr={1} />
                          <Text>From the end</Text>
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </ButtonGroup>
            )}
          </Flex>
        </Flex>
        {(isFetchingInsights) && (
            <Stack p={3} spacing={2} backgroundColor={'white'}>
              <Skeleton height='50px' />
              <Skeleton height='50px' />
              <Skeleton height='50px' />
            </Stack>
        )}
        <Tabs
          h="500px"
          flex={1}
          flexDirection={"column"}
          display={"flex"}
          backgroundColor="background.grey"
          index={tabIndex}
          variant="orangeFlat"
          onChange={(index) => setTabIndex(index)} >
          {!!archived.length && (
            <TabList
              backgroundColor="white"
              borderBottom="1px"
              borderRadius="2px"
              borderColor="divider.grey"
            >
              <Tab ref={activepanelRef}>
                {tabIndex === 0 && <Text>Current ({actives.length})</Text>}
                {tabIndex !== 0 && <Text  >Current ({actives.length})</Text>}
              </Tab>
              <Tab>
                {tabIndex === 1 && <Text>Outdated ({archived.length})</Text>}
                {tabIndex !== 1 && <Text  >Outdated ({archived.length})</Text>}
              </Tab>
            </TabList>
          )}
          {!isFetchingInsights && (
            <TabPanels
              height={"500px"}
              display={"flex"}
              flex={1}
              flexDirection={"column"}
            >
              <TabPanel
                flex="1"
                display="flex"
                flexDirection={"column"}
                height={"500px"}
                ref={panelRef}
              >
                {!!actives.length && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={"drop-area-active"}>
                      {(droppableProvided, droppableSnapshot) => (
                        <Flex
                          flexDirection={"column"}
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                          height={"500px"}
                          flex={1}
                          overflow="auto"
                          p={3}
                        >
                          {!!actives.length && actives.map((insight, i) => {
                            return (
                              <Draggable
                                key={insight.id}
                                draggableId={`${insight.id}`}
                                index={insight.order}
                                isDragDisabled={!rights.can_update}
                              >
                                {(draggableProvided, draggableSnapshot) => (
                                  <InsightProvider
                                    insightId={insight.id}
                                    originalInsight={insight}
                                    onInsightChange={() => {
                                      if(needScroll && i === actives.length - 1) {
                                        performScroll();
                                      }
                                    }}
                                    >
                                    <Flex
                                      ref={el => scrollRefActive.current[i] = el}
                                      w={"100%"}
                                      flexDirection={"column"}
                                      mb={2}
                                      id={insight.id}
                                      borderWidth="1px"
                                      borderStyle="solid"
                                      borderColor="divider.grey"
                                      borderRadius="2px"
                                      cursor={rights.can_update ? "grab" : "default"}
                                      outlineColor={
                                        draggableSnapshot.isDragging
                                          ? "card-border"
                                          : "transparent"
                                      }
                                      boxShadow={
                                        draggableSnapshot.isDragging
                                          ? "0px 0px 6px 0px rgba(163,163,163,0.15)"
                                          : "unset"
                                      }
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                    >
                                      <InsightListItem
                                        personaId={personaId}
                                        orderMax={actives.length}
                                        onMovedToStart={() => {
                                          setCurrentScroll(1)
                                          setNeedScroll(true);
                                          performScroll();
                                          refreshInsights();
                                            if (onChange) {
                                              onChange();
                                            }
                                          }}
                                          onMovedToEnd={() => {
                                          setCurrentScroll(panelRef.current.firstChild.scrollHeight)
                                          setNeedScroll(true);
                                          performScroll();
                                            refreshInsights();
                                            if (onChange) {
                                              onChange();
                                            }
                                          }}
                                        onArchived={() => {
                                          refreshInsights();
                                          if (onChange) {
                                            onChange();
                                          }
                                        }}
                                        onDelete={() => {
                                          if(i === actives.length - 1) {
                                            setCurrentScroll(panelRef.current.firstChild.scrollTop - 300)
                                          } else if(i === 0 ) {
                                            setCurrentScroll(1)
                                          } else {
                                            setCurrentScroll(panelRef.current.firstChild.scrollTop)
                                          }
                                          setNeedScroll(true);
                                          performScroll();
                                          refreshInsights();
                                          if (onChange) {
                                            onChange();
                                          }
                                        }}
                                        onUnarchived={() => {
                                          if (archived.length === 1) {
                                            setTabIndex(0);
                                          }
                                          if (onChange) {
                                            onChange();
                                          }
                                          refreshInsights();
                                        }}
                                        key={i}
                                      />
                                    </Flex>
                                  </InsightProvider>
                                )}
                              </Draggable>
                            );
                          })}
                          {droppableProvided.placeholder}
                        </Flex>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                {!actives.length && (
                  <Flex
                    p={3}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    flex={"1"}
                  >
                    <Text color={'text.medium.grey'} mb={3} >No Insight so far</Text>
                    {rights.can_update && (
                      <Box pb={3}>
                        {getMenu()}
                      </Box>
                    )}
                  </Flex>
                )}
              </TabPanel>
              <TabPanel
                flex="1"
                display="flex"
                flexDirection={"column"}
                height={"100%"}
                ref={panelRefArchived}
              >
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={"drop-area-archived"}>
                    {(droppableProvided, droppableSnapshot) => (
                      <Flex
                        flexDirection={"column"}
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        height={"500px"}
                        overflow={"auto"}
                        flex={1}
                        p={3}
                      >
                        {!!archived.length && archived.map((insight, i) => {
                          return (
                            <Draggable
                              key={insight.id}
                              draggableId={`${insight.id}`}
                              index={insight.order}
                              isDragDisabled={!rights.can_update}
                            >
                              {(draggableProvided, draggableSnapshot) => (
                                <InsightProvider insightId={insight.id}>
                                  <Flex
                                    w={"100%"}
                                    flexDirection={"column"}
                                    mb={2}
                                    borderWidth="1px"
                                    borderStyle="solid"
                                    borderColor="divider.grey"
                                    borderRadius="2px"
                                    cursor="grab"
                                    outlineColor={
                                      draggableSnapshot.isDragging
                                        ? "card-border"
                                        : "transparent"
                                    }
                                    boxShadow={
                                      draggableSnapshot.isDragging
                                        ? "0px 0px 6px 0px rgba(163,163,163,0.15)"
                                        : "unset"
                                    }
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    <InsightListItem
                                      personaId={personaId}
                                      orderMax={archived.length}
                                      onMovedToStart={() => {
                                      setCurrentScroll(1)
                                      setNeedScroll(true);
                                      performScroll();
                                      refreshInsights();
                                        if (onChange) {
                                          onChange();
                                        }
                                      }}
                                      onMovedToEnd={() => {
                                      setCurrentScroll(panelRefArchived.current.firstChild.scrollHeight)
                                      setNeedScroll(true);
                                      performScroll();
                                        refreshInsights();
                                        if (onChange) {
                                          onChange();
                                        }
                                      }}
                                      onArchived={() => {
                                        refreshInsights();
                                        if (onChange) {
                                          onChange();
                                        }
                                      }}
                                      onUnarchived={() => {
                                        if (archived.length === 1) {
                                          setTabIndex(0);
                                        }
                                        if (onChange) {
                                          onChange();
                                        }
                                        refreshInsights();
                                      }}
                                      onDelete={() => {
                                        if (archived.length === 1) {
                                          setTabIndex(0);
                                        }
                                        if(i === archived.length - 1) {
                                          setCurrentScroll(panelRefArchived.current.firstChild.scrollTop - 300)
                                        } else if(i === 0 ) {
                                          setCurrentScroll(1)
                                        } else {
                                          setCurrentScroll(panelRefArchived.current.firstChild.scrollTop)
                                        }
                                        setNeedScroll(true);
                                        performScroll();
                                        refreshInsights();
                                        if (onChange) {
                                          onChange();
                                        }
                                      }}
                                      key={i}
                                    />
                                  </Flex>
                                </InsightProvider>
                              )}
                            </Draggable>
                          );
                        })}
                        {droppableProvided.placeholder}
                      </Flex>
                    )}
                  </Droppable>
                </DragDropContext>
                {!archived.length && (
                  <Box p={3} >
                    <Text color={'text.medium.grey'}>No Outdated Insight</Text>
                  </Box>
                )}
              </TabPanel>
            </TabPanels>
          )}
        </Tabs>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent >
            {(type === insightTypeDictionnary.positive) && (
              <ModalHeader color='fill.green' >
                <Flex alignItems={'center'}>
                  <Icon
                    as={MdOutlineSentimentVerySatisfied}
                    color={'fill.green'}
                    boxSize={'20px'}
                    mr={2}
                  />Add Positive Feedback
                </Flex>
              </ModalHeader>
            )}
            {(type === insightTypeDictionnary.negative) && (
              <ModalHeader color='fill.red' >
                <Flex alignItems={'center'}>
                  <Icon
                    as={MdOutlineSentimentVeryDissatisfied}
                    color={'fill.red'}
                    boxSize={'20px'}
                    mr={2}
                  />Add Negative Feedback
                </Flex>
              </ModalHeader>
            )}
            {(type === insightTypeDictionnary.improvement) && (
              <ModalHeader color='fill.yellow' >
                <Flex alignItems={'center'}>
                  <Icon
                    as={PiHandsPrayingFill}
                    color={'fill.yellow'}
                    boxSize={'20px'}
                    mr={2}
                  />Add Improvement Feedback
                </Flex>
              </ModalHeader>
            )}
            {(type === insightTypeDictionnary.behavior) && (
              <ModalHeader color='fill.grey' >
                <Flex alignItems={'center'}>
                  <Icon
                    as={MdOutlineTouchApp}
                    color={'fill.grey'}
                    boxSize={'20px'}
                    mr={2}
                  />Add Behavior
                </Flex>
              </ModalHeader>
            )}
            {(type === insightTypeDictionnary.motivation) && (
              <ModalHeader color='fill.purple' >
                <Flex alignItems={'center'}>
                  <Icon
                    as={CognitionIcon}
                    color={'fill.purple'}
                    boxSize={'20px'}
                    mr={2}
                  />Add Motivation
                </Flex>
              </ModalHeader>
            )}
            <ModalBody >
              <Box p={3}>
                {isOpen && (
                  <>
                    <InsightForm
                      type={type}
                      experienceId={experienceId}
                      hideSave={true}
                      onValidityChange={(validityState) => {
                        setFormValidity(validityState);
                      }}
                      onSubmitting={() => {
                        setIsAdding(true);
                        setTabIndex(0)
                      }}
                      onSubmitted={() => {
                        setIsAdding(false);
                        refreshInsights();
                        setNeedScroll(true);
                        onClose();
                        if (onChange) {
                          onChange();
                        }
                      }}
                      ref={ref}
                    />
                    {!experienceId && (
                      <Button
                        variant='tertiary'
                        onClick={() => {
                          modalCreateInsight.onOpen();
                          onClose()
                        }}
                      >Create new Experience</Button>
                    )}
                  </>
                )}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='orangeOutline'
                onClick={onClose}
              >Close</Button>
              <Button
                variant="orangeFill"
                ml={2}
                isDisabled={!formValidity}
                isLoading={isAdding}
                onClick={() => {
                  ref.current.click();
                }}>Save</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={modalCreateInsight.isOpen} onClose={modalCreateInsight.onClose}>
          <ModalOverlay />
          <ModalContent >
            <ModalHeader>
              <IconButton
                fontSize='24'
                variant="tertiary"
                icon={<MdArrowBack />}
                aria-label='New'
                mr={2}
                onClick={() => {
                  modalCreateInsight.onClose();
                  onOpen();
                }}
              />
              New Experience</ModalHeader>
            <ModalBody >
              <Box p={3}>
                <ExperienceForm
                  productId={productId}
                  personaId={personaId}
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalCreateInsight.onClose();
                    onOpen();
                  }}
                  ref={ref1} />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='orangeOutline'
                onClick={() => {
                  modalCreateInsight.onClose();
                  onOpen();
                }}
              >Close</Button>
              <Button
                variant="orangeFill"
                ml={2}
                isLoading={isAdding}
                onClick={() => {
                  ref1.current.click();
                }}>Save</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
}
