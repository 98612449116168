'use client'

import React, {useEffect, useState} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react'
import ProductSelectedMembersListItem from './productSelectedMembersListItem';

export default function ProductSelectedMembers(props) {
  
  const {
    selectedMembers,
    onRemoveSelectedMember
  } = props;
  const [hasNotExistingUser, setHasNotExistingUser] = useState(false);
  const [selectedMembersStatus, setSelectedMembersStatus] = useState([]);

  const _setSelectedMembersStatus = (status) => {
    const newSelectedMembersStatus = Array.from(selectedMembersStatus);
    newSelectedMembersStatus.push(status);
    setSelectedMembersStatus(newSelectedMembersStatus);
  }

  const _onRemoveSelectedMember = (m, i) => {
    const newSelectedMembersStatus = Array.from(selectedMembersStatus);
    newSelectedMembersStatus.forEach((s, i) => {
      if(s.email === m ) {
        newSelectedMembersStatus.splice(i, 1);
      }
    });
    setSelectedMembersStatus(newSelectedMembersStatus);
    if(onRemoveSelectedMember) {
      onRemoveSelectedMember(i);
    }
  }

  useEffect(() => {
    if(selectedMembersStatus.length > 0 ) {
      const allSelectedHaveProfile = selectedMembersStatus.every((s) => {
        return s.profile === true;
      });
      setHasNotExistingUser(!allSelectedHaveProfile);
    } else {
      setHasNotExistingUser(false)
    }
  }, [selectedMembersStatus]);

  useEffect(() => {
  }, [hasNotExistingUser]);

  return (
    <>
      <Flex
        borderWidth="1px"
        borderColor="divider.grey"
        borderRadius="2px"
        borderStyle={'solid'}
        backgroundColor={'white'}
        flexWrap={'wrap'}
        px={3}
        py={2}
        mb={2}
        gap={2}
      >
         {selectedMembers && selectedMembers.map((m, i) => {
      return (
        <Box key={i} onClick={() => {
          _onRemoveSelectedMember(m, i);
        }}>
          <ProductSelectedMembersListItem 
            onFetchProfileStatus={_setSelectedMembersStatus}
            invited={m}
          />
        </Box>
      )
    })}
    </Flex>
    {hasNotExistingUser && (
      <Box
        border="1px"
        borderColor="divider.grey"
        backgroundColor="white"
        p={3}
        mb={2}
      >
        <Text
        variant="body"
        fontWeight="medium"
        color="text.medium.yellow">One or several persons are not users of Bibliona. They will need to sign up before viewing this product.</Text>
      </Box>
    )}
    </>
  )
}




